
import validate from '../../../services/validator'
import category from '../../../services/category'
import router from '../../../router'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, reactive, ref, UnwrapRef, onMounted } from 'vue'
import { notification, message } from 'ant-design-vue'
import { PlusOutlined, SaveOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  type?: string;
  size?: number;
  preview?: string;
  originFileObj?: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

interface FormState {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parent_id: any;
  description: string;
  image: string;
}
export default defineComponent({
  components: {
    PlusOutlined,
    SaveOutlined
  },
  props: ['parentCategories'],
  setup () {
    const categoryForm: UnwrapRef<FormState> = reactive({
      title: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      parent_id: '0',
      description: '',
      image: ''
    })
    const rules = ref<any>(validate.categoryValidation)
    const fileList = ref([])
    const fileSize = ref<any | undefined>('')
    const fileName = ref<string | undefined>('')
    const loading = ref<boolean>(false)
    const formRef = ref()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const handleFinishFailed = (errors: ValidateErrorEntity<FormState>) => {
      console.log('errors', errors)
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }
    const route = useRoute()
    const previewVisible = ref<boolean>(false)
    const previewImage = ref<string | undefined>('')
    let editFlag = false
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const imageUploadUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_FILE_UPLOAD
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handleChange = ({ fileList: newFileList }: FileInfo) => {
      if (fileList.value.length > 0) {
        let size: any = fileList.value[0].size / 1024 / 1024
        size = parseFloat(size).toFixed(2)
        fileSize.value = size
        fileName.value = fileList.value[0].name
        const s3Response: any = fileList.value[0].response
        fileList.value = newFileList
        if (s3Response) {
          categoryForm.image = s3Response.Location
        }
      }
    }
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
      categoryForm.image = ''
      fileSize.value = ''
      fileName.value = ''
    }
    const beforeUpload = (file: FileItem) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        message.error('Image must smaller than 5MB!')
      }
      return isJpgOrPng && isLt2M
    }
    const createCategory = async (values: FormState) => {
      loading.value = true
      try {
        if (editFlag) {
          values.parent_id = categoryForm.parent_id
          let message = 'Category Updated Successfully'
          if (values.parent_id !== '0') {
            message = 'Sub Category Updated Successfully'
          }
          const res = await category.updateCategory(values, route.params.id)
          if (typeof res.data === 'string') {
            await notify('Error', res.data, 'error')
          } else {
            router.push('/category')
            await notify('Success', message, 'success')
          }
          loading.value = false
        } else {
          let message = 'Category Added Successfully'
          if (route.params.sub) {
            values.parent_id = route.params.id
            message = 'Sub Category Added Successfully'
          } else {
            values.parent_id = '0'
          }
          const res = await category.storeCategory(values)
          if (typeof res.data === 'string') {
            await notify('Error', res.data, 'error')
          } else {
            router.push('/category')
            await notify('Success', message, 'success')
          }
          loading.value = false
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const getCategoryDetails = async (id) => {
      try {
        const res = await category.getCategoryDetails(id)
        categoryForm.title = res.data.title
        // eslint-disable-next-line @typescript-eslint/camelcase
        categoryForm.parent_id = res.data.parent_id
        categoryForm.description = res.data.description
        categoryForm.image = res.data.image
        if (categoryForm.image) {
          fileList.value.push(
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: categoryForm.image
            }
          )
        }
      } catch (error) {
        console.log(error)
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      if (route.params.id && (!route.params.sub || route.params.sub === 'sub')) {
        editFlag = true
        getCategoryDetails(route.params.id)
      }
      if (route.params.sub) {
        rules.value = validate.subCategoryValidation
      }
    })
    return {
      categoryForm,
      rules,
      loading,
      formRef,
      notify,
      handleFinishFailed,
      resetForm,
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      handleChange,
      createCategory,
      headers,
      imageUploadUrl,
      handleRemove,
      beforeUpload,
      fileSize,
      fileName
    }
  }
})
